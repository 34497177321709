/* global window */

import { history } from "umi"
import { stringify } from "qs"
import store from "store"
import { ROLE_TYPE } from "utils/constant"
import { queryLayout } from "utils"
import { CANCEL_REQUEST_MESSAGE } from "utils/constant"
import api from "api"
import config from "config"
import { message } from "antd"
import { isEmpty } from "lodash"
import { pathToRegexp } from "path-to-regexp"

const { getEstate, logoutUser, queryUserInfo } = api

const goHomePage = () => {
	if (
		pathToRegexp(["/", "/login", "/register", "/resetPassword"]).exec(
			window.location.pathname,
		)
	) {
		history.push({
			pathname: store.get("resident") ? "/work-orders" : "/dashboard",
		})
	}
}
const estateModal = {
	status: false,
	name: "",
	type: "",
	address: "",
	planNum: "",
}
const initState = {
	estateList: [],
	selectedEstate: {},
	estateModal,
	locationPathname: "",
	locationQuery: {},
	theme: store.get("theme") || "dark",
	collapsed: store.get("collapsed") || false,
	initEstateId:
		store.get("selectedEstate") && store.get("selectedEstate").estate_id,
	// notifications: [
	// 	{
	// 		title: "New User is registered.",
	// 		date: new Date(Date.now() - 10000000),
	// 	},
	// 	{
	// 		title: "Application has been approved.",
	// 		date: new Date(Date.now() - 50000000),
	// 	},
	// ],
}
export default {
	namespace: "app",
	state: {
		...initState,
	},
	subscriptions: {
		setup({ dispatch, history }) {
			history.listen(location => {
				if (location.pathname.includes("landingpage") && !store.get("user"))
					return
				const { query } = location || {}
				const { code, email, redeem } = query
				code &&
					email &&
					dispatch({
						type: "updateState",
						payload: {
							code,
							email,
							redeem,
						},
					})
				if (
					!pathToRegexp("/resetPassword").exec(location.pathname) &&
					!pathToRegexp("/login").exec(location.pathname) &&
					!pathToRegexp("/register").exec(location.pathname)
				) {
					// dispatch({
					// 	type:'resetPassword/updateState',
					// })
					dispatch({ type: "query" })
				}
			})
		},
		setupHistory({ dispatch, history }) {
			history.listen(location => {
				dispatch({
					type: "updateState",
					payload: {
						locationPathname: location.pathname,
						locationQuery: location.query,
					},
				})
			})
		},

		setupRequestCancel({ history }) {
			history.listen(() => {
				const { cancelRequest = new Map() } = window
				cancelRequest.forEach((value, key) => {
					if (value.pathname !== window.location.pathname) {
						value.cancel(CANCEL_REQUEST_MESSAGE)
						cancelRequest.delete(key)
					}
				})
			})
		},
	},
	effects: {
		*getEstate({ }, { call, put, select }) {
			const { selectedEstate, initEstateId } = yield select(_ => _.app)
			//get estate
			if (selectedEstate && selectedEstate.estate_id) {
				return true
			}
			const estate = yield call(getEstate) || {}
			const success = estate && estate.success
			if (!success) {
				// console.log(success)
				// store.clearAll()
				// history.push({ pathname: "/login" })
				return
			}
			const data = estate && estate.data
			if (success) {
				yield put({
					type: "updateState",
					payload: { estateList: (data && data.list) || [] },
				})
				if (JSON.stringify(selectedEstate) === "{}") {
					if (initEstateId) {
						yield put({
							type: "selectedEstate",
							payload:
								(data &&
									data.list.find(i => i.estate_id === initEstateId - 0)) ||
								{},
						})
					} else {
						yield put({
							type: "selectedEstate",
							payload: (data && data.list)[0] || {},
						})
					}
				}
				if (!(data && data.list)[0])
					return message.error("No estate list! Please check.")
			}
			return true
		},
		*query({ }, { put, select }) {
			const { redeem, code } = yield select(_ => _.app)
			const { invitationCode } = yield select(_ => _.login)
			if (redeem) {
				yield put({
					type: "login/confirm",
					payload: { invitationCode: code },
				})
				return
			}
			// store isInit to prevent query trigger by refresh
			const isInit = store.get("isInit")
			if (isInit) {
				return goHomePage()
			}
			const { locationPathname } = yield select(_ => _.app)
			if (store.get("token")) {
				// const { success, user } = yield call(queryUserInfo, payload)

				// if (success && user) {
				// const { list } = yield call(queryRouteList)
				// const { permissions } = user
				// let routeList = list
				// if (
				//   permissions.role === ROLE_TYPE.ADMIN ||
				//   permissions.role === ROLE_TYPE.DEVELOPER
				// ) {
				//   permissions.visit = list.map(item => item.id)
				// } else {
				//   routeList = list.filter(item => {
				//     const cases = [
				//       permissions.visit.includes(item.id),
				//       item.mpid
				//         ? permissions.visit.includes(item.mpid) || item.mpid === '-1'
				//         : true,
				//       item.bpid ? permissions.visit.includes(item.bpid) : true,
				//     ]
				//     return cases.every(_ => _)
				//   })
				// }
				// store.set('routeList', routeList)
				// store.set('permissions', permissions)
				// store.set('user', user)
				store.set("isInit", true)
				return goHomePage()
			}
			if (queryLayout(config.layouts, locationPathname) !== "public") {
				if (store.get("user")) return
				if (window.location.pathname.includes("/login")) {
					!invitationCode &&
						!code &&
						history.push({
							pathname: "/login",
							search: stringify({
								from: locationPathname,
							}),
						})
					return
				}

				window.location.pathname = "/landingpage/index.html"
			}
		},

		// eslint-disable-next-line no-empty-pattern
		*signOut({ }, { put }) {
			// const data = yield call(logoutUser)
			// if(data.success){
			store.set("routeList", [])
			store.set("permissions", { visit: [] })
			store.set("user", {})
			store.set("isInit", false)
			store.set("token", "")
			store.clearAll()
			yield put({ type: "updateState", payload: initState })
			yield put({ type: "query" })
			history.push("/login")
			// } else {
			//   throw data
			// }
		},
	},
	reducers: {
		updateState(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},

		handleThemeChange(state, { payload }) {
			store.set("theme", payload)
			state.theme = payload
		},

		handleCollapseChange(state, { payload }) {
			store.set("collapsed", payload)
			state.collapsed = payload
		},
		selectedEstate(state, { payload }) {
			if (isEmpty(payload)) {
				throw new Error("payload")
			}
			return {
				...state,
				selectedEstate: payload,
			}
		},
		allNotificationsRead(state) {
			state.notifications = []
		},
		newEstateModal(state) {
			return {
				...state,
				estateModal: {
					...state.estateModal,
					status: true,
				},
			}
		},
		initEstateModal(state) {
			return {
				...state,
				estateModal,
			}
		},
		estateModal(state, { payload }) {
			return {
				...state,
				estateModal: {
					...state.estateModal,
					...payload,
				},
			}
		},
	},
}
