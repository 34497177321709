import axios from "axios"
import { cloneDeep } from "lodash"
import { history } from "umi"
import { message } from "antd"
import { CANCEL_REQUEST_MESSAGE } from "utils/constant"
import RequestControl from "./requestControl"
import  basement  from "../../package.json"
import store from "store"
import { parse, compile } from "path-to-regexp"
const { CancelToken } = axios
window.cancelRequest = new Map()
const { VERSION=1, maxLimit=1 } = basement
function request(options, anthoerOptions) {
	let { data, url, method = "get" } = options
	const cloneData = cloneDeep(data)

	try {
		let domain = ""
		const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/)
		if (urlMatch) {
			;[domain] = urlMatch
			url = url.slice(domain.length)
		}

		const match = parse(url)
		url = compile(url)(data)

		for (const item of match) {
			if (item instanceof Object && item.name in cloneData) {
				delete cloneData[item.name]
			}
		}
		url = domain + url
	} catch (e) {
		message.error(e.message)
	}

	options.url = url
	if (method && method.toLowerCase() === "get") {
		options.params = cloneData
	}
	options.cancelToken = new CancelToken(cancel => {
		window.cancelRequest.set(Symbol(Date.now()), {
			pathname: window.location.pathname,
			cancel,
		})
	})
	axios.defaults.withCredentials = true
	axios.interceptors.request.use(
		function(config) {
			const Authorization = store.get("token")
			if (Authorization) {
				config.headers["Authorization"] = Authorization
			}
			if (VERSION) {
				config.headers["Version"] = VERSION
			}
			return config
		},
		function(error) {
			return Promise.reject(error)
		},
	)

	return axios({ ...options, ...(anthoerOptions || {}) })
		.then(response => {
			if (anthoerOptions) {
				return Promise.resolve({
					success: true,
					message: "ok",
					response,
				})
			}
			//FIXME:need server.js
			const { statusText, status, data } = response
			let result = {}
			if (data === null) {
				store.clearAll()
				history.push({ pathname: "/" })
			}
			if (typeof data === "object") {
				result = data
				if (Array.isArray(data)) {
					result.list = data
				}
			} else {
				result.data = data
			}

			return Promise.resolve({
				success: true,
				message: statusText,
				statusCode: status,
				...result,
			})
		})
		.catch(error => {
			const { response, message } = error

			if (String(message) === CANCEL_REQUEST_MESSAGE) {
				return {
					success: false,
				}
			}

			let msg
			let statusCode

			if (response && response instanceof Object) {
				const { data, statusText } = response
				statusCode = response.status
				msg = data.message || statusText
			} else {
				statusCode = 600
				msg = error.message || "Network Error"
			}

			/* eslint-disable */
			return Promise.reject({
				success: false,
				statusCode,
				message: msg,
			})
		})
}
const requestControl = new RequestControl({ maxLimit, request })
export default function(options, anotherOptions) {
	return requestControl.requestControl(options, anotherOptions)
}
