/*
 * @Author: your name
 * @Date: 2020-09-11 09:39:01
 * @LastEditTime: 2021-09-08 10:05:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ctg\basement\basementWeb\src\components\Layout\Menu.js
 */
import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
// import { Icon as LegacyIcon } from "@ant-design/compatible"
import Icon from "@ant-design/icons"
import { Menu } from "antd"
import { NavLink, withRouter } from "umi"
import { arrayToTree, queryAncestors } from "utils"
import store from "store"
import styles from "./Menu.less"
import { getSVG } from "utils/SVGMapping"
import { pathToRegexp } from "path-to-regexp"
import { routeList as route } from "../../pages/login/route"
import { routeList as resident_route } from "../../pages/login/resident_route"
const { SubMenu } = Menu
@withRouter
class SiderMenu extends PureComponent {
	state = {
		openKeys: store.get("openKeys") || [],
	}
	componentDidMount() {
		store.set("routeList", store.get("resident") ? resident_route : route)
	}
	componentDidUpdate() {}
	onOpenChange = openKeys => {
		const { menus } = this.props
		const rootSubmenuKeys = menus.filter(_ => !_.menuParentId).map(_ => _.id)

		const latestOpenKey = openKeys.find(
			key => this.state.openKeys.indexOf(key) === -1,
		)

		let newOpenKeys = openKeys
		if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
			newOpenKeys = latestOpenKey ? [latestOpenKey] : []
		}

		this.setState({
			openKeys: newOpenKeys,
		})
		store.set("openKeys", newOpenKeys)
	}
	mapToCompenent = iconName => getSVG(iconName)
	generateMenus = (data, selectedKeys) => {
		return data.map(item => {
			if (item.children) {
				return (
					<SubMenu
						key={item.id}
						className={styles.menuName}
						icon={
							()=><Icon
								component={() => (
									<>
										{item.icon === "BuildingIcon" && (
											<img src={require("./icon_png/icon_building_gray.png")} />
										)}
										{item.icon === "TenantIcon" && (
											<img src={require("./icon_png/icon_tenant_gray.png")} />
										)}
									</>
								)}
							></Icon>
						}
						title={
							<Fragment>
								<span> {item.name} </span>
							</Fragment>
						}
					>
						{this.generateMenus(item.children)}
					</SubMenu>
				)
			}
			return (
				<Menu.Item
					icon={
						()=><Icon
							component={() => (
								<>
									{item.icon === "DashboardIcon" &&
										(selectedKeys.includes(item.id) ? (
											<img
												src={require("./icon_png/icon_dashboard_white.png")}
											/>
										) : (
											<img
												src={require("./icon_png/icon_dashboard_gray.png")}
											/>
										))}
									{item.icon === "BookingIcon" &&
										(selectedKeys.includes(item.id) ? (
											<img src={require("./icon_png/icon_booking_white.png")} />
										) : (
											<img src={require("./icon_png/icon_booking_gray.png")} />
										))}
									{item.icon === "AppsIcon" &&
										(selectedKeys.includes(item.id) ? (
											<img src={require("./icon_png/icon_apps_white.png")} />
										) : (
											<img src={require("./icon_png/icon_apps_grey.png")} />
										))}
									{item.icon === "MaintenanceIcon" &&
										(selectedKeys.includes(item.id) ? (
											<img
												src={require("./icon_png/icon_maintenance_white.png")}
											/>
										) : (
											<img
												src={require("./icon_png/icon_maintenance_gray.png")}
											/>
										))}
									{item.icon === "PaymentIcon" &&
										(selectedKeys.includes(item.id) ? (
											<img src={require("./icon_png/icon_payment_white.png")} />
										) : (
											<img src={require("./icon_png/icon_payment_gray.png")} />
										))}
								</>
							)}
						/>
					}
					key={item.id}
				>
					<NavLink to={item.route || "#"}>
						{/* {!store.get("collapsed") && ( */}
						<span className={styles.menuName}> {item.name} </span>
						{/* )} */}
					</NavLink>
				</Menu.Item>
			)
		})
	}
	render() {
		const { collapsed, theme, menus, location, isMobile, onCollapseChange } =
			this.props
		// Generating tree-structured data for menu content.
		const menuTree = arrayToTree(menus, "id", "menuParentId")
		// Find a menu that matches the pathname.
		const currentMenu = menus.find(
			_ =>
				(_.route && pathToRegexp(_.route).exec(location.pathname)) ||
				(_.route &&
					_.route.includes("/apps") &&
					pathToRegexp(
						!store.get("resident")
							? "/apps/:item"
							: `/apps/${location.pathname}`,
					).exec(location.pathname)),
		)

		// Find the key that should be selected according to the current menu.
		const selectedKeys = currentMenu
			? queryAncestors(menus, currentMenu, "menuParentId").map(_ => _.id)
			: []

		const menuProps = collapsed
			? {}
			: {
					openKeys: this.state.openKeys,
			  }

		return (
			<Menu
				mode="inline"
				theme={theme}
				onOpenChange={this.onOpenChange}
				selectedKeys={selectedKeys}
				// defaultSelectedKeys={(() => [history.location.pathname])()}
				// defaultOpenKeys={(() => {
				// 	if (history.location.pathname === '/facilities' || history.location.pathname === '/venues') return ['2']
				// })()}
				onClick={
					isMobile
						? () => {
								onCollapseChange(true)
						  }
						: undefined
				}
				{...menuProps}
			>
				{this.generateMenus(menuTree, selectedKeys)}
			</Menu>
		)
	}
}

SiderMenu.propTypes = {
	menus: PropTypes.array,
	theme: PropTypes.string,
	isMobile: PropTypes.bool,
	onCollapseChange: PropTypes.func,
}

export default SiderMenu
